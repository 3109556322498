import { Environment } from '../main/types/environment'
import { sharedBackendRelativePaths, sharedEnvironment } from './shared-environment'

export const environment: Environment = {
  NODE_ENV: 'production',
  REACT_APP_AUTH0_DOMAIN: 'login.eu-pactum.com',
  REACT_APP_AUTH0_CLIENT: 'P83jTQdrCehfDcNZ6GXF5n9NTePfUNkT',
  REACT_APP_AUTH0_BACKEND_API: 'https://api.eu-pactum.com/api/v1',
  REACT_APP_GOOGLE_PLACES_API_KEY: 'AIzaSyDkkOo6gCSiOPd_h01u5N_L2UBamgFqblc',
  ...sharedBackendRelativePaths,
  ...sharedEnvironment,
}
